import { format, addDays } from 'date-fns';

let minPickupDate = new Date();
minPickupDate.setHours(0);
minPickupDate.setMinutes(0);

let minPickupTime = new Date();
minPickupTime = addDays(minPickupTime, Number(format(minPickupDate, 'i')));
minPickupTime.setHours(12);
minPickupTime.setMinutes(0);

let minReturnDate = new Date();
minReturnDate = addDays(minReturnDate, Number(format(minReturnDate, 'i')));
minReturnDate.setHours(0);
minReturnDate.setMinutes(0);

let minReturntime = new Date();
minReturntime = addDays(minReturntime, Number(format(minReturnDate, 'i')));
minReturntime.setHours(12);
minReturntime.setMinutes(0);

export { minPickupDate, minPickupTime, minReturnDate, minReturntime };
