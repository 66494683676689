import { PropsWithChildren } from 'react';

import { AnimatePresence } from 'framer-motion';
import styles from 'styles/components/Modal.module.css';

import Button from './Button';

export type ModalProps = PropsWithChildren & {
  isOpen: boolean;
  title: string;
  onClose?: () => void;
  onConfirm?: () => void;
  confirmText?: string;
};

export default function Modal({
  isOpen,
  title,
  children,
  onClose = () => {},
  onConfirm = () => {},
  confirmText = 'Ok',
}: ModalProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className={styles.modal} onClick={onClose}>
          <div
            className={styles.modal__body}
            onClick={e => e.stopPropagation()}
          >
            <div className={styles.modal__content}>
              {title && <h3 className={styles.title}>{title}</h3>}
              <div className={styles.modal__body__content}>{children}</div>
            </div>
            <hr className={styles.content__divider} />
            <div className={styles.modal__action}>
              <Button variant="success" onClick={onConfirm}>
                {confirmText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
}
