import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { addDays, parse, format } from 'date-fns';
import { Formik, Form, Field, FieldProps } from 'formik';
import NextRightIcon from 'public/icons/keyboard_arrow_right.svg';

import styles from '../styles/Home.module.css';
import { api } from '@/api/api';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import DateField from '@/components/DateField';
import Flex from '@/components/Flex';
import FormGroup from '@/components/FormGroup';
import SelectField from '@/components/SelectField';
import {
  schema,
  FormFieldsType,
  initialValues,
  timeOptions,
} from '@/forms/itinerary';
import { minPickupDate } from '@/helpers/itinerary';
import { DepotType } from '@/types';
import { useReservationBar } from '@/contexts/reservationBar';
import Modal from 'components/Modal';
import { buildAndPushSearchEvent } from '@/utils/dataLayer/search';
import { brFormatToUsaFormat, kayakFormatToUsaFormat, kayakTimeToNormalTime } from '@/helpers/fdate';
interface HomeProps {
  depots: DepotType[];
  depotsOptions: any;
  serverErrors: any;
}

const modalDefault = {
  isOpen: false,
  title: 'Alerta',
  message: '',
}

export default function Home({ depotsOptions, serverErrors }: HomeProps) {
  const router = useRouter();
  const { setValue, updateBar } = useReservationBar();
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(modalDefault);

  useEffect(() => {
    updateBar({
      title: 'Itinerário',
      step: 1,
      progress: 0,
    });
  }, []);

  useEffect(() => {
    if (typeof router.query.uiCode !== 'undefined') {
      const uiCode = router.query.uiCode;
      router.replace('/', undefined, { shallow: true });

      switch (uiCode) {
        case '2724':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'Não é possível drop off entre essas duas lojas'
          });
          break;
          // case 'RT29':
          //   setModal({
          //     isOpen: true,
          //     title: 'Aviso',
          //     message: 'Número máximo de reservas é de 29 dias'
          //   });
          //   break;
        case 'DT30':
          setModal({
            isOpen: true,
            title: 'Aviso',
            message: 'O horário escolhido está muito próximo. Escolha um horário com no mínimo 30 minutos de antecedência'
          });
          break;
        default:
          setModal({
            isOpen: true,
            title: 'Erro',
            message: 'Houve um erro, favor tentar novamente'
          });
      }
    }
  }, [router.query]);

  const onSubmit = (values: FormFieldsType) => {
    if (values.return_store === 'SAME') {
      values.return_store = values.pickup_store;
    }

    const depotOption = depotsOptions.find(
      (depot: any) => depot.value === values.pickup_store,
    );

    let query: any = {
      pickup_store: values.pickup_store,
      pickup_date: values.pickup_date.toISOString().split('T')[0],
      pickup_time: values.pickup_time,
      return_store: values.return_store,
      return_date: values.return_date.toISOString().split('T')[0],
      return_time: values.return_time,
      driver: router.query.driver || 21,
      coupon: values.promo_code || '',
    };

    if (router.query.cdp_id) {
      query = {
        ...query,
        cdp_id: router.query.cdp_id,
      }
    }

    // Envie o evento para o dataLayer
    buildAndPushSearchEvent(values, router.query.coupon, depotsOptions);

    setIsLoading(true);
    setValue(1, depotOption.label);

    router.push({
      pathname: '/veiculos',
      query,
    })
      .then(() => setIsLoading(false));
    // fetch(values as ItineraryType);
  };

  const getInitialValue = (key: string, initial: any): any => {
    if (typeof router.query[key] === 'string') {
      const value = decodeURIComponent(router.query[key] as string);

      if (key === 'pickup_date' || key === 'return_date') {
        const formats = ['dd/MM/yyyy', 'dd/MM/yy'];
        let parseFormat = 'yyyy-MM-dd';

        if (formats.includes(router.query.date_format as string)) {
          parseFormat = router.query.date_format as string;
        }

        const parsed = parse(value, parseFormat, new Date());
        parsed.setHours(12);
        parsed.setMinutes(0);
        return parsed.toString() === 'Invalid Date' ? initial : parsed;
      }

      if (key === 'pickup_time' || key === 'return_time') {
        const [hour, time] = (router.query[key] as string)?.split(':');
        const auxDate = new Date();
        auxDate.setHours(hour as any);
        auxDate.setMinutes(time as any);

        return format(auxDate, 'HH:mm');
      }

      return value;
    }

    return initial;
  }

  const closeModal = () => {
    setModal(modalDefault);
  }

  return (
    <div>
      <Modal
        isOpen={modal.isOpen}
        title={modal.title}
        onClose={() => closeModal()}
        onConfirm={() => closeModal()}
      >
        {modal.message}
      </Modal>
      <div className={styles.form__wrap}>
        <h2 className={styles.content__title}>Itinerário</h2>
        <Formik
          initialValues={{
            pickup_store: getInitialValue('pickup_store', ''),
            pickup_date: getInitialValue('pickup_date', addDays(new Date(), 1)),
            // pickup_time: format(addHours(new Date(), 1), 'HH:00'),
            pickup_time: getInitialValue('pickup_time', '12:00'),
            return_store: getInitialValue('return_store', 'SAME'),
            return_date: getInitialValue('return_date', addDays(new Date(), 2)),
            // return_time: format(addHours(addDays(new Date(), 2), 1), 'HH:00'),
            return_time: getInitialValue('return_time', '12:00'),
            promo_code: getInitialValue('coupon', ""),
          }}
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({ errors, touched, setFieldValue, values, setTouched }) => (
            <Form>
              <div>
                <div className={styles.form__inputs}>
                  {/* PICKUP */}
                  <Flex direction="column" flex={1}>
                    <FormGroup
                      label="Local de retirada"
                      htmlFor="pickup_store"
                      errors={errors?.pickup_store}
                      touched={touched?.pickup_store}
                    >
                      {/* PICKUP LOCATION */}
                      <Field
                        className={
                          errors?.pickup_store && touched?.pickup_store
                            ? 'field__has_error'
                            : ''
                        }
                        name="pickup_store"
                        id="pickup_store"
                        component={SelectField}
                        options={depotsOptions}
                      />
                    </FormGroup>

                    <Flex direction="row" gap={'1rem'}>
                      {/* PICKUP DATE */}
                      <FormGroup
                        label="Data da Retirada"
                        htmlFor="pickup_date"
                        errors={errors?.pickup_date as string}
                        touched={true}
                      >
                        <Field
                          className={
                            errors?.pickup_date ? 'field__has_error' : ''
                          }
                          name="pickup_date"
                          id="pickup_date"
                          component={DateField}
                          minDate={minPickupDate}
                          onChange={(val: Date) => {
                            if (
                              !touched.return_date ||
                              val > values.return_date
                            ) {
                              setFieldValue('return_date', addDays(val, 1));
                            }
                          }}
                          withPortal
                        />
                      </FormGroup>

                      {/* PICKUP TIME */}
                      <FormGroup
                        label="Hora da Retirada"
                        htmlFor="pickup_time"
                        errors={errors?.pickup_time}
                        touched={true}
                      >
                        <Field
                          className={
                            errors?.pickup_time && touched?.pickup_time
                              ? 'field__has_error'
                              : ''
                          }
                          name="pickup_time"
                          id="pickup_time"
                          component={SelectField}
                          options={timeOptions}
                        />
                      </FormGroup>
                    </Flex>

                    {/* Cupom*/}
                    <FormGroup
                      label="Cupom"
                      htmlFor="promo_code"
                      errors={errors?.promo_code}
                      touched={true}
                    >
                      <Field
                        className={`text__field ${errors?.promo_code && touched?.promo_code
                          ? 'field__has_error'
                          : ''
                          }`}
                        name="promo_code"
                        id="promo_code"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = event.target;
                          setFieldValue('promo_code', value.toUpperCase());
                        }}
                      />
                    </FormGroup>
                  </Flex>

                  {/* RETURN */}
                  <Flex direction="column" flex={1}>
                    {/* RETURN LOCATION */}
                    <FormGroup
                      label="Local de Devolução"
                      htmlFor="return_store"
                      errors={errors?.return_store}
                      touched={touched?.return_store}
                    >
                      <Field
                        className={
                          errors?.return_store && touched?.return_store
                            ? 'field__has_error'
                            : ''
                        }
                        name="return_store"
                        id="return_store"
                        component={SelectField}
                        options={[
                          {
                            label: 'Mesmo local de retirada',
                            value: 'SAME',
                          },
                          ...depotsOptions,
                        ]}
                      />
                    </FormGroup>

                    <Flex direction="row" gap={'1rem'}>
                      {/* RETURN DATE */}
                      <FormGroup
                        label="Data da Devolução"
                        htmlFor="return_date"
                        errors={errors?.return_date as string}
                        touched={true}
                      >
                        <Field
                          className={
                            errors?.return_date ? 'field__has_error' : ''
                          }
                          name="return_date"
                          id="return_date"
                        >
                          {({ field, form, meta }: FieldProps) => (
                            <DateField
                              form={form}
                              meta={meta}
                              minDate={values.pickup_date}
                              withPortal
                              field={field}
                              type="date"
                              onBlur={() => setTouched({ return_date: true })}
                            />
                          )}
                        </Field>
                      </FormGroup>

                      {/* RETURN TIME */}
                      <FormGroup
                        label="Hora da Devolução"
                        htmlFor="return_time"
                        errors={errors?.return_time}
                        touched={true}
                      >
                        <Field
                          className={
                            errors?.return_time && touched?.return_time
                              ? 'field__has_error'
                              : ''
                          }
                          name="return_time"
                          id="return_time"
                          component={SelectField}
                          options={timeOptions}
                        />
                      </FormGroup>
                    </Flex>


                  </Flex>
                </div>

                <FormGroup isBtnSubmit>
                  <Button
                    className={styles.next__button}
                    type="submit"
                    hasRightIcon
                    isLoading={isLoading}
                  >
                    {isLoading ? (
                      'Carregando ...'
                    ) : (
                      <>
                        Avançar <NextRightIcon />
                      </>
                    )}
                  </Button>
                </FormGroup>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export interface DepotOptionType {
  label: string;
  value: string;
}

export async function getServerSideProps({ query }: any) {
  const depotsOptions: DepotOptionType[] = [];
  const serverErrors: any[] = [];

  if (query.origin === 'SKYSCANNER' && typeof query.deeplink === 'undefined') {
    const { pickup_location, pickup_date, return_location, return_date, ...rest } = query;

    const params = new URLSearchParams({
      pickup_store: pickup_location,
      pickup_date: brFormatToUsaFormat(pickup_date),
      return_store: return_location,
      return_date: brFormatToUsaFormat(return_date),
      ...rest,
      driver: query.driver || 21,
      coupon: query.coupon || '',
      deeplink: 1,
    });

    return {
      redirect: {
        permanent: false,
        destination: '/veiculos?' + params.toString(),
      }
    }
  } else if (query.utm_source === 'kayak' && typeof query.deeplink === 'undefined') {
    const { PICKUP_LOC, PICKUP_DATE, PICKUP_MONTH_YEAR, PICKUP_TIME, DROPOFF_LOC, DROPOFF_DATE, DROPOFF_MONTH_YEAR, DROPOFF_TIME, ...rest } = query;

    const params = new URLSearchParams({
      pickup_store: PICKUP_LOC,
      pickup_date: kayakFormatToUsaFormat(PICKUP_DATE, PICKUP_MONTH_YEAR),
      pickup_time: kayakTimeToNormalTime(PICKUP_TIME),
      return_store: DROPOFF_LOC,
      return_date: kayakFormatToUsaFormat(DROPOFF_DATE, DROPOFF_MONTH_YEAR),
      return_time: kayakTimeToNormalTime(DROPOFF_TIME),
      cdp_id: 'KAYAK',
      ...rest,
      driver: query.driver || 21,
      coupon: query.coupon || '',
      origin: 'KAYAK',
      deeplink: 1,
    });

    return {
      redirect: {
        permanent: false,
        destination: '/veiculos?' + params.toString(),
      }
    }
  }

  try {
    const { data } = await api.get('/depots');

    data.sort((a:DepotType, b:DepotType) => {
      if (a.title < b.title) return -1
      if (a.title > b.title) return 1
      return 0
    } ).map((depot: DepotType) => {
      depotsOptions.push({
        label: depot.title,
        value: depot.coral_code as string,
      });
    });

    return {
      props: {
        depots: data,
        depotsOptions,
        serverErrors,
      },
    };
  } catch (err) {
    return {
      props: {
        depots: [],
        depotsOptions,
        serverErrors: [
          {
            code: 0,
            message:
              'Houve um erro no servidor, não conseguimos carregar as lojas',
          },
        ],
      },
    };
  }
}
