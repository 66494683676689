// utils/dataLayerUtils.ts
import { format } from 'date-fns';

// Tipagem para os objetos dentro do seu array depotsOptions
export interface DepotOption {
  value: string;
  label: string;
}

// Função para formatar a data ISO para o formato DD/MM/YY
export function formatDate(dateString: string): string {
  const date = new Date(dateString + 'T12:00');
  return format(date, 'dd/MM/yy');
}

// Função para obter o label pelo pickup_store
export function getLabelByPickupStore(pickupStore: string, depotsOptions: DepotOption[]): string | undefined {
  const depotOption = depotsOptions.find(depot => depot.value === pickupStore);
  return depotOption ? depotOption.label : undefined;
}

export function getCouponQueryParam(queryParam: unknown): string | undefined {
  if (typeof queryParam === 'string' && queryParam.trim() !== '') {
    return queryParam;
  }
  return undefined;
}


export function formatDateWithTime(dateString: string): string {
  const date = new Date(dateString);
  return format(date, 'dd/MM/yy');
}