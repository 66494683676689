import { PropsWithChildren, useEffect } from 'react';

import styles from 'styles/FormGroup.module.css';

type FormGroupProps = PropsWithChildren & {
  label?: string;
  htmlFor?: string;
  errors?: string;
  touched?: boolean;
  isBtnSubmit?: boolean;
  className?: string;
};

export default function FormGroup({
  children,
  label,
  htmlFor,
  errors,
  touched,
  isBtnSubmit,
  className,
}: FormGroupProps) {
  return (
    <div
      className={`${styles.formGroup} ${isBtnSubmit && styles['isBtnSubmit']
        } ${className}`}
    >
      {label && (
        <label className={styles.label} htmlFor={htmlFor}>
          {label}
        </label>
      )}
      {children}
      {errors && touched && (
        <div className={styles.formGroup__error}>{errors}</div>
      )}
    </div>
  );
}
