import { DepotOption, formatDate, getCouponQueryParam, getLabelByPickupStore } from "./dataLayerutils";

// Tipagem para os dados do evento de pesquisa
export interface SearchEventData {
  search_term: string;
  local_retirada: string;
  data_retirada: string;
  hora_retirada: string;
  local_devolucao: string;
  data_devolucao: string;
  hora_devolucao: string;
  tipo_documento: string;
  tipo_plano?: string;
  franquia?: string;
  cupom_desconto?: string;
}

// Função para construir e enviar o evento de pesquisa para o dataLayer
export function buildAndPushSearchEvent(values: any, coupon: unknown, depotsOptions: DepotOption[]): void {
  const eventData: SearchEventData = {
    search_term: `RT|${values.pickup_store}-${values.return_store}|${formatDate(values.pickup_date.toISOString().split('T')[0])}-${formatDate(values.return_date.toISOString().split('T')[0])}|${values.pickup_time}-${values.return_time}`,
    local_retirada: getLabelByPickupStore(values.pickup_store, depotsOptions) || '',
    data_retirada: formatDate(values.pickup_date.toISOString().split('T')[0]),
    hora_retirada: values.pickup_time,
    local_devolucao: getLabelByPickupStore(values.return_store, depotsOptions) || '',
    data_devolucao: formatDate(values.return_date.toISOString().split('T')[0]),
    hora_devolucao: values.return_time,
    tipo_documento: 'CNH definitiva',
    tipo_plano: values.tipo_plano || '',
    franquia: values.franquia || '',
    cupom_desconto: getCouponQueryParam(coupon) || ''
  };

  // Envie o evento para o dataLayer
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    'event': 'search',
    ...eventData
  });
}
