import { PropsWithChildren } from 'react';

import { Property } from 'csstype';
import styles from 'styles/Flex.module.css';

export type FlexProps = PropsWithChildren & {
  columnGap?: number;
  desktop?: boolean;
  className?: string;
  direction?: Property.FlexDirection;
  flex?: Property.Flex;
  gap?: Property.Gap;
};

export default function Flex({
  children,
  columnGap,
  desktop,
  className,
  direction = 'row',
  flex = 'none',
  gap = 0,
}: FlexProps) {
  return (
    <div
      className={`${styles.flex} ${desktop && styles.desktop} ${className}`}
      style={{ columnGap: columnGap || 0, flexDirection: direction, flex, gap }}
    >
      {children}
    </div>
  );
}
