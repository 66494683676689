import { ptBR } from 'date-fns/locale';

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { IMaskInput } from 'react-imask';

import 'react-datepicker/dist/react-datepicker.css';
import { FieldProps } from 'formik';

type DateFieldProps = FieldProps & {
  type: 'date' | 'time';
  className?: string;
  placeholderText?: string;
  placeholder?: string;
  minDate?: Date;
  withPortal?: boolean;
  onChange?: (val: Date) => void;
  onBlur?: (e: any) => void;
};

registerLocale('pt-BR', ptBR);
setDefaultLocale('pt-BR');

export default function DateField({
  field,
  type = 'date',
  form,
  className = '',
  onChange,
  onBlur,
  ...props
}: DateFieldProps) {
  const formats = {
    date: 'dd/MM/yyyy',
    time: 'HH:mm',
  };
  const masks = {
    date: '00/00/0000',
    time: '00:00',
  };

  const handleChange = (val: Date): void => {
    form.setFieldValue(field.name, val);
    if (onChange) onChange(val);
  };

  return (
    <div className={type}>
      {!props.withPortal && type === 'date' ? (
        <DatePicker
          {...props}
          dateFormat={formats[type]}
          selected={(field.value && new Date(field.value)) || null}
          onChange={handleChange}
          autoComplete="off"
          customInput={
            <IMaskInput
              // className={`text__field date__field ${className}`}
              mask={masks[type]}
              // placeholder={placeholderText || placeholder}
            />
          }
        />
      ) : (
        <DatePicker
          {...props}
          className={`text__field date__field ${className}`}
          autoComplete="off"
          onBlur={onBlur}
          dateFormat={formats[type]}
          selected={field.value}
          onChange={handleChange}
        />
      )}
    </div>
  );
}
